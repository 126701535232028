import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {ITariff, IVehicle} from "../../lib/types/types";
import SelectTariffDropdown from "./SelectTariffDropdown";
import PreviewTariff from "./PreviewTariff";
import {VehicleService} from "../../services/vehicleService";
import {Button} from "primereact/button";
import {useDialog} from "../../lib/hooks/useDialog";
import {useToast} from "../../lib/hooks/useToast";

interface Props {
    firmTariffs?: ITariff[];
    selectedVehicle?: IVehicle;
    successCb?: () => void;
}

const AddTariffToVehicle: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {hideDialog} = useDialog();
    const { showToast } = useToast();

    const vehicleService = new VehicleService();

    const [selectedFirmTariff, setSelectedFirmTariff] = useState<ITariff>();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const handleSave = () => {
        if(!props.selectedVehicle || !selectedFirmTariff) return;
        vehicleService.assignTariffs([selectedFirmTariff.tarifId], [props.selectedVehicle?.vehicleId])
            .then(resp => {
                showToast("success", f({ id: "done" }), f({ id: "tariffAddedToVehiclew" }));
                if(props.successCb) props.successCb();
                hideDialog();
            }).catch(console.error);
    }

    return <>
        <div className={"w-full flex grid p-3"}>
            <div className={"col-12"}>
                <SelectTariffDropdown label={f({id: "selectFirmTariff"})} handleTariffSelected={setSelectedFirmTariff}
                                      tariffOptions={props.firmTariffs || []} selectedTariff={selectedFirmTariff}/>
            </div>

            {selectedFirmTariff &&
                <>
                    <PreviewTariff tariff={selectedFirmTariff}/>
                    <div className={"col-12 flex justify-content-between"}>
                        <Button className={"col-5"} label={f({id: "save"})} onClick={handleSave} />
                        <Button className={"col-5"} label={f({id: "cancel"})} severity={"warning"} onClick={hideDialog} />
                    </div>
                </>
            }
        </div>
    </>
};

export default AddTariffToVehicle
