import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {ITariff, IVehicle} from "../../lib/types/types";
import {FloatLabel} from "primereact/floatlabel";
import {InputText} from "primereact/inputtext";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {Button} from "primereact/button";
import {useConfirmDialog} from "../../lib/hooks/useConfirmDialog";
import {ClientFirmService} from "../../services/clientFirmService";
import {useToast} from "../../lib/hooks/useToast";
import {useDialog} from "../../lib/hooks/useDialog";
import SelectTariffDropdown from "./SelectTariffDropdown";
import PreviewTariff from "./PreviewTariff";

interface Props {
    targetTariff: ITariff;
    tariffOptions: ITariff[];
    getVehicles: (sourceTariff: ITariff) => Promise<IVehicle[]>;
    onCreateCb: () => void
}

const UnifyTariffs: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {showConfirmDialog} = useConfirmDialog();
    const {hideDialog} = useDialog();
    const {showToast} = useToast();

    const clientFirmService = new ClientFirmService();

    const [targetTariffOptions, setTargetTariffOptions] = useState<ITariff[]>();
    const [selectedSourceTariff, setSelectedSourceTariff] = useState<ITariff>();
    const [selectedTargetTariff, setSelectedTargetTariff] = useState<ITariff>();
    const [vehicles, setVehicles] = useState<IVehicle[]>([]);
    const [disableSave, setDisableSave] = useState(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if (!props.tariffOptions) return;

        setTargetTariffOptions(props.tariffOptions.map(el => {
            return {
                ...el,
                id: `${el.tarifName}-${el.tarifType.tarifTypeId}-${el.tarifDescription}-${generateTariffKey(el)}`
            }
        }));
    }, [props.tariffOptions]);

    useEffect(() => {
        if(!props.targetTariff) return;

        setSelectedTargetTariff(props.targetTariff);

    }, [props.targetTariff]);

    useEffect(() => {
        if(!selectedSourceTariff) return;
        setDisableSave(true)
        const tempTariff = {...selectedSourceTariff};
        //@ts-ignore
        delete tempTariff.id
        props.getVehicles(tempTariff)
            .then(vehicles => {
                setVehicles(vehicles);
                setDisableSave(false);
            })
    }, [selectedSourceTariff]);

    const generateTariffKey = (tariff: ITariff) => {
        return `${tariff.januaryPrice}-${tariff.februaryPrice}-${tariff.marchPrice}-${tariff.aprilPrice}-${tariff.mayPrice}-${tariff.junePrice}-${tariff.julyPrice}-${tariff.augustPrice}-${tariff.septemberPrice}-${tariff.octoberPrice}-${tariff.novemberPrice}-${tariff.decemberPrice}`
    }

    const handleUnify = () => {
        if (!selectedTargetTariff || !selectedSourceTariff) return;
        const sourceTariffWithoutId = {...selectedSourceTariff};
        //@ts-ignore
        delete sourceTariffWithoutId.id
        const vehicleLpns = <ul>
            {vehicles.slice(0, 10).map(v => <li>{v.vehicleLpn}</li>)}
            {vehicles.length > 10 && <b>{f({id: "andMore"}, {count: vehicles.length - 10})}</b>}
        </ul>

        showConfirmDialog({
            body: f({id: "confirmMassUpdateTariff"}, {
                vehicleCount: <b>{vehicles.length}</b>,
                vehicleLpns: vehicleLpns
            })
        }).then(answer => {
            if (answer) {
                clientFirmService.massUpdateTariff(sourceTariffWithoutId, selectedTargetTariff, vehicles.map(el => el.vehicleId))
                    .then(resp => {
                        if (resp) {
                            showToast("success", f({id: "done"}), f({id: "tariffUpdated"}));
                            props.onCreateCb();
                            setSelectedSourceTariff(undefined);
                        }
                    })
                    .catch((err) => {
                        showToast("error", f({id: "error"}), f({id: "exceptionOccurred"}));
                    })
            } else {

            }
        })
    }

    const shouldDisableUnify = () => {
        if(disableSave) return true;
        else return selectedSourceTariff === undefined || selectedTargetTariff === undefined
    }

    return <div className={"w-full flex grid p-3"}>
        <div className={"col-12 md:col-6"}>
            <SelectTariffDropdown label={f({id: "targetTariff"})} handleTariffSelected={setSelectedTargetTariff} tariffOptions={props.tariffOptions} selectedTariff={selectedTargetTariff} />
        </div>

        <div className={"col-12 md:col-6"}>
            <SelectTariffDropdown label={f({id: "sourceTariff"})} handleTariffSelected={setSelectedSourceTariff} tariffOptions={props.tariffOptions} excludeTariff={selectedTargetTariff}/>
        </div>



        {selectedTargetTariff && <PreviewTariff tariff={selectedTargetTariff} label={f({id: "targetTariff"})} />}

        {selectedSourceTariff && <PreviewTariff tariff={selectedSourceTariff} label={f({id: "targetTariff"})}/>}

        <div className={"col-12 flex"}>
            <div className={"col-6 justify-content-end flex"}>
                <Button severity={"warning"} className={"col-8"} label={f({id: "unify"})}
                        disabled={shouldDisableUnify()} onClick={handleUnify}/>
            </div>
            <div className={"col-6 justify-content-start flex"}>
                <Button className={"col-8"} label={f({id: "cancel"})}/>
            </div>
        </div>


    </div>
};

export default UnifyTariffs
