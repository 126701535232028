import * as React from 'react';
import {useContext, useEffect} from 'react';
import {useIntl} from "react-intl";
import {PermissionContext} from "../lib/context/PermissionsContext";
import {Card} from "primereact/card";
import ClientFirmsSummaryDashboard from "./dashboard/ClientFirmsSummaryDashboard";

interface Props {
}

export const Home: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const {allowAction} = useContext(PermissionContext);

    return <>
        {/*{allowAction(['InternalTasks', 'Employee']) &&*/}
        {/*    <Card className={"border-round-3xl"}>*/}
        {/*        <InternalTasks/>*/}
        {/*    </Card>*/}
        {/*}*/}

        {allowAction(["ClientFirmsVehiclesByTariffSummary"]) &&
            <Card className={"border-round-3xl"}>
                <ClientFirmsSummaryDashboard/>
            </Card>
            }

    </>
};
