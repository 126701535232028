import {useIntl} from 'react-intl';
import * as React from 'react';
import {FloatLabel} from "primereact/floatlabel";
import {InputText} from "primereact/inputtext";
import {ITariff} from "../../lib/types/types";

interface Props {
    tariff: ITariff;
    label?: string;
    fullScreen?: boolean;
}

const PreviewTariff: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();

    return <div className={`flex flex-column col-12 ${props.fullScreen && "md:col-6"}`}>
        {props.label &&
            <div className={"col-12 mt-3"}>
                <h2>{props.label}</h2>
            </div>
        }

        <div className={"col-12 mt-3"}>
            <FloatLabel>
                <InputText value={props.tariff?.tarifName} disabled={true} className={"w-full"}/>
                <label>{f({id: "tariffName"})}</label>
            </FloatLabel>
        </div>

        <div className={"col-12 mt-3"}>
            <FloatLabel>
                <InputText value={props.tariff?.tarifType.tarifTypeName} disabled={true}
                           className={"w-full"}/>
                <label>{f({id: "tariffType"})}</label>
            </FloatLabel>
        </div>

        <div className={"col-12 mt-3"}>
            <FloatLabel className={"w-full"}>
                <InputText value={props.tariff?.tarifDescription} disabled={true} className={"w-full"}/>
                <label>{f({id: "description"})}</label>
            </FloatLabel>
        </div>

        {Object.keys(props.tariff)
            .filter(el => el.includes('Price'))
            .map((key: string, index) => {
                    return <div className={"col-12 mt-3"} key={"targetTariff-" + index}>
                        <FloatLabel className={"w-full"}>
                            <InputText value={props.tariff[key as keyof ITariff] as string} disabled={true}
                                       className={"w-full"}/>
                            <label htmlFor={key}>{f({id: key.replace("Price", '')})}</label>
                        </FloatLabel>
                    </div>
                }
            )}
    </div>
};

export default PreviewTariff
