import React, {useCallback, useEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import Chart from "chart.js/auto";
import {ChartData} from "chart.js";
import {ClientFirmService} from "../../services/clientFirmService";
import {
    ClientFirmsTariffSummary,
    ClientFirmVehiclesByTariff, IClientFirm,
    ITariff, IVehicle,
} from "../../lib/types/types";
import {HeaderButton, ReactiveTable} from "react-ive-tables";
import {DataTableRowToggleEvent} from "primereact/datatable";
import {Skeleton} from "primereact/skeleton";
import {Button} from "primereact/button";
import {TariffsForVehicle} from "../tariffs/TariffsForVehicle";
import {useDialog} from "../../lib/hooks/useDialog";
import UnifyTariffs from "../tariffs/UnifyTariffs";
import MassEditSelectTariffWrapper from "../tariffs/massEditTariff/MassEditSelectTariffWrapper";
import {SelectButton, SelectButtonChangeEvent} from "primereact/selectbutton";

export interface TariffSummaryMainTable {
    tariffName: string;
    vehiclesCount: number;
    tariffTypeName: string;
    index: number
}

const ClientFirmsSummaryDashboard: React.FC = () => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const allVehiclesChart = useRef<HTMLCanvasElement>(null);
    const allVehiclesChartInstance = useRef<Chart | null>(null);
    const {showDialog, hideDialog} = useDialog();

    const tariffsChart = useRef<HTMLCanvasElement>(null);
    const tariffsChartInstance = useRef<Chart | null>(null);

    const clientFirmService = new ClientFirmService();

    const [tariffSummary, setTariffSummary] = useState<ClientFirmsTariffSummary | undefined>();
    const [selectedDataset, setSelectedDataset] = useState<ClientFirmVehiclesByTariff[]>([]);
    const [expandedRows, setExpandedRows] = useState<{[key: number]: boolean}>([]);
    const [mainTableExpandedRows, setMainTableExpandedRows] = useState<any>([]);
    const [rebuildColumns, setRebuildColumns] = useState<Date>();
    const [showPieSkeleton, setShowPieSkeleton] = useState(true);
    const [showTableSkeleton, setShowTableSkeleton] = useState(false);
    const [selectedTariffToUpdate, setSelectedTariffToUpdate] = useState<ITariff>();
    const [showTable, setShowTable] = useState(false);
    const [selectedType, setSelectedType] = useState<"invoiced" | "uninvoiced" | undefined>(undefined);
    const [selectedTariffTypeChart, setSelectedTariffTypeChart] = useState<"year" | "month" | undefined>("year");
    const [selectedTariffSummaryForMainTable, setSelectedTariffSummaryForMainTable] = useState<TariffSummaryMainTable[]>()

    const tariffSummaryRef = useRef<ClientFirmsTariffSummary | undefined>(undefined);
    tariffSummaryRef.current = tariffSummary; // Keep the ref updated

    // Handles clicking on chart segments
    const handleChartClick = (event: MouseEvent, elements: any) => {
        if (elements && elements.length > 0 && allVehiclesChartInstance.current) {
            const clickedIndex = elements[0].index;
            const dataset = allVehiclesChartInstance.current.data.datasets[0];
            // Toggle offset for clicked segment
            //@ts-ignore
            if (dataset && dataset.offset) {
                //@ts-ignore
                dataset.offset = dataset.offset.map((el, index) => {
                    if (index === clickedIndex) {
                        return el === 50 ? 0 : 50;
                    }
                    return 0
                });
                allVehiclesChartInstance.current.update(); // Update chart to reflect changes

                // handleShowTariffsChart(clickedIndex)
                setSelectedType(clickedIndex === 0 ? "invoiced" : "uninvoiced");
                setShowTable(true);
            }
        }
    }

    useEffect(() => {
        if (!selectedType || !selectedTariffTypeChart) return;
        setExpandedRows({});
        setMainTableExpandedRows({});
        handleShowTariffsChart(selectedType, selectedTariffTypeChart);
    }, [selectedType, selectedTariffTypeChart])

    const handleShowTariffsChart = (selectedType: "invoiced" | "uninvoiced", selectedTariffTypeChart: "year" | "month") => {
        let dataForTariffChart: number[] = [];
        let labels: string[] = []
        let colorsMap: string[] = [];

        const currentTariffSummary = tariffSummaryRef.current; // Access the latest summary from the ref

        let dataset = [];

        if (selectedType === "invoiced") {
            if (selectedTariffTypeChart === "year") {
                dataset = currentTariffSummary?.invoicedVehicles.filter(el => el.vehiclesCount > 0 && el.tariff.tarifType.tarifTypeId === 11) || [];
            } else {
                dataset = currentTariffSummary?.invoicedVehicles.filter(el => el.vehiclesCount > 0 && el.tariff.tarifType.tarifTypeId !== 11) || [];
            }
        } else {
            if (selectedTariffTypeChart === "year") {
                dataset = currentTariffSummary?.unInvoicedVehicles.filter(el => el.vehiclesCount > 0 && el.tariff.tarifType.tarifTypeId === 11) || [];
            } else {
                dataset = currentTariffSummary?.unInvoicedVehicles.filter(el => el.vehiclesCount > 0 && el.tariff.tarifType.tarifTypeId !== 11) || [];
            }
        }

        dataForTariffChart = dataset.map(el => el.vehiclesCount) || []
        labels = dataset.map(el => el.tariff.tarifName) || []
        colorsMap = dataset.map(el => "#" + Math.floor(Math.random() * 16777215).toString(16)) || [];

        setSelectedTariffSummaryForMainTable(dataset.map((el, index) => {
                return {
                    tariffName: el.tariff.tarifName,
                    tariffTypeName: el.tariff.tarifType.tarifTypeName,
                    vehiclesCount: el.vehiclesCount,
                    index: index,
                }
            })
        )


        const data: ChartData<"pie", number[], unknown> = {
            labels: labels,
            datasets: [
                {
                    data: dataForTariffChart,
                    backgroundColor: colorsMap
                },
            ],
        };

        // Chart options
        const options = {
            plugins: {
                title: {
                    display: true,
                    text: f({id: selectedType}),
                    font: {weight: "bold", size: 18},
                    position: "top",
                },
                legend: {
                    position: "bottom",
                    labels: {usePointStyle: true},
                    align: "start",
                    fullSize: false
                },
            },
            onClick: (event: MouseEvent, elements: any) => handleSecondChartClick(event, elements, selectedType === "invoiced", selectedTariffTypeChart),
        };

        if (tariffsChart.current) {
            if (tariffsChartInstance.current) {
                tariffsChartInstance.current.data = data;
                //@ts-ignore
                tariffsChartInstance.current.options = options
                tariffsChartInstance.current.update();
            } else {
                tariffsChartInstance.current = new Chart(tariffsChart.current, {
                    type: "pie",
                    data,
                    // @ts-ignore
                    options,
                });
            }

        }
    }

    const handleSecondChartClick = (_: MouseEvent, elements: any, invoiced: boolean, selectedTariffType: "month" | "year") => {
        if (tariffSummaryRef && tariffSummaryRef.current && elements && elements.length > 0) {
            loadVehiclesByTariff(elements[0].index, invoiced, selectedTariffType);
        }
    }

    const handleMainTableRowExpansion = (e: DataTableRowToggleEvent) => {
        if(Object.keys(e.data).length === 0) {
            setMainTableExpandedRows({});
            return;
        }

        const currentlyExpandedRow = Object.keys(mainTableExpandedRows)[0];
        const newExpandedIndex = Object.keys(e.data).filter(el => el !== currentlyExpandedRow).pop();
        if(!newExpandedIndex) return;
        setMainTableExpandedRows({[newExpandedIndex] : true});


        loadVehiclesByTariff(+newExpandedIndex, selectedType === "invoiced", selectedTariffTypeChart!);
    }

    const loadVehiclesByTariff = (index: number, invoiced: boolean, selectedTariffType: "month" | "year") => {
        if (!tariffSummaryRef || !tariffSummaryRef.current) return;
        setMainTableExpandedRows({[index] : true});
        setShowTableSkeleton(true);
        let tariff: ITariff | null = null;
        if (invoiced) {
            if (selectedTariffType === "month") {
                tariff = tariffSummaryRef.current.invoicedVehicles.filter(el => el.vehiclesCount > 0 && el.tariff.tarifType.tarifTypeId !== 11)[index].tariff;
            } else {
                tariff = tariffSummaryRef.current.invoicedVehicles.filter(el => el.vehiclesCount > 0 && el.tariff.tarifType.tarifTypeId === 11)[index].tariff;
            }
        } else {
            if (selectedTariffType === "month") {
                tariff = tariffSummaryRef.current.unInvoicedVehicles.filter(el => el.vehiclesCount > 0 && el.tariff.tarifType.tarifTypeId !== 11)[index].tariff;
            } else {
                tariff = tariffSummaryRef.current.unInvoicedVehicles.filter(el => el.vehiclesCount > 0 && el.tariff.tarifType.tarifTypeId === 11)[index].tariff;
            }
        }
        setSelectedTariffToUpdate(tariff);
        getVehiclesByTariffSummary(tariff, invoiced);
    }

    const getVehiclesByTariffSummary = (tariff: ITariff, invoiced?: boolean): Promise<IVehicle[]> => {
        return clientFirmService.vehiclesByTariffSummary(invoiced ? invoiced : selectedType === "invoiced", tariff)
            .then(resp => {
                setRebuildColumns(new Date());
                setSelectedDataset(resp);
                setShowTableSkeleton(false);
                return resp.map(el => el.vehicles).flat();


            })
    }

    const getSummaryData = (skipPreloader?: boolean) => {
        clientFirmService.tariffSummary(skipPreloader).then((resp) => {
            setShowPieSkeleton(false);
            if (resp && allVehiclesChart.current) {
                //Filter all records which have 0 vehicles
                let filteredRespData = {...resp};
                filteredRespData.invoicedVehicles = filteredRespData?.invoicedVehicles.filter(el => el.vehiclesCount > 0) || [];
                filteredRespData.unInvoicedVehicles = filteredRespData?.unInvoicedVehicles.filter(el => el.vehiclesCount > 0) || [];

                setTariffSummary(filteredRespData);
                const documentStyle = getComputedStyle(document.documentElement);

                // Prepare chart data
                const data: ChartData<"pie", number[], unknown> = {
                    labels: [
                        f({id: "invoiced"}),
                        f({id: "uninvoiced"}),
                        f({id: "noTariffVehicles"}),
                    ],
                    datasets: [
                        {
                            data: [resp.invoicedVehiclesCount, resp.unInvoicedVehiclesCount, resp.noTariffVehiclesCount],
                            offset: [0, 0, 0],
                            backgroundColor: [
                                documentStyle.getPropertyValue("--blue-500"),
                                documentStyle.getPropertyValue("--red-500"),
                                documentStyle.getPropertyValue("--orange-500"),
                            ],
                            hoverBackgroundColor: [
                                documentStyle.getPropertyValue("--blue-400"),
                                documentStyle.getPropertyValue("--red-400"),
                                documentStyle.getPropertyValue("--orange-400"),
                            ],
                        },
                    ],
                };
                // Chart options
                const options = {
                    plugins: {
                        title: {
                            display: true,
                            text: f({id: "allVehicles"}),
                            font: {weight: "bold", size: 18},
                            position: "top",
                        },
                        legend: {
                            position: "bottom",
                            labels: {usePointStyle: true},
                            align: "start",
                        },
                    },
                    onClick: handleChartClick,
                    hoverOffset: 50,
                };

                // Destroy existing chart if necessary
                if (allVehiclesChartInstance.current) {
                    allVehiclesChartInstance.current.update();
                } else {
                    // Create new chart
                    allVehiclesChartInstance.current = new Chart(allVehiclesChart.current, {
                        type: "pie",
                        data,
                        // @ts-ignore
                        options,
                    });
                }

            }
        });
    }

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
            // Fetch data and initialize chart
            getSummaryData(true);
        }


        // Cleanup on component unmount
        return () => {
            if (allVehiclesChartInstance.current) {
                allVehiclesChartInstance.current.destroy();
            }
            if (tariffsChartInstance.current) {
                tariffsChartInstance.current.destroy();
            }
        };
    }, []);

    const getVehiclesColumnTemplate = () => {
        return {
            tariffs: (rowData: IVehicle) => <Button label={f({id: "seeTariffs"})}
                                                    onClick={() => handleSeeTariffsButton(rowData)}/>
        }
    }

    const handleSeeTariffsButton = (vehicle: IVehicle) => {
        showDialog({
            newContent: <TariffsForVehicle selectedVehicle={vehicle} clientFirmId={vehicle.clientFirmId}/>,
            heading: f({id: "tariffsForVehicleLpn"}, {lpn: vehicle.vehicleLpn}),
            style: {maxWidth: "80vw"}
        })
    }

    const mainTableRowExpansionTemplate = (data: TariffSummaryMainTable) => {
        // const vehiclesForFirm = selectedDataset.find(el => el.clientFirm.clientFirmId === data.clientFirmId);
        // if (vehiclesForFirm) {
        return showTableSkeleton ?
            <Skeleton width="80vw" height="30vh" borderRadius="5rem"></Skeleton>
            :
            <div className="p-3 flex w-full justify-content-center align-items-center"
                 style={{maxWidth: "83vw"}}>
                <ReactiveTable columnOrder={["clientFirmName", "salesPersonName", "vehiclesCount"]}
                               data={selectedDataset.map(el => el.clientFirm)}
                               showFilters={false}
                               showPaginator={false}
                               wrapperClassName={"w-full"}
                               rebuildColumns={rebuildColumns?.getTime()}
                               selectionKey={"clientFirmId"}
                               expandable
                               showHeader={true}
                               headerButtons={headerButtons}
                               columnTemplate={getClientFirmsColumnTemplate()}
                               paginatorOptions={[100000]}
                               specialLabels={{
                                   vehiclesCount: "vehiclesWithSelectedTariff"
                               }}
                               dtProps={{
                                   rowExpansionTemplate: rowExpansionTemplate,
                                   expandedRows: expandedRows,
                                   onRowToggle: handleRowToggle,
                               }}
                />

            </div>

    };

    const rowExpansionTemplate = (data: IClientFirm) => {
        const vehiclesForFirm = selectedDataset.find(el => el.clientFirm.clientFirmId === data.clientFirmId);
        if (vehiclesForFirm) {
            return <div className="p-3 flex w-full justify-content-center align-items-center"
                        style={{maxWidth: "83vw"}}>
                <ReactiveTable
                    showHeader={false}
                    wrapperClassName={"w-full"}
                    columnOrder={["vehicleLpn", "tariffs"]}
                    data={vehiclesForFirm.vehicles}
                    selectionKey={"vehicleLpn"}
                    columnTemplate={getVehiclesColumnTemplate()}
                    showFilters={false}
                />

            </div>
        }

    };

    const handleRowToggle = (e: DataTableRowToggleEvent) => {
        setExpandedRows(e.data);
    }

    const getClientFirmsColumnTemplate = () => {
        return {
            vehiclesCount: (rowData: IClientFirm) => selectedDataset.find(el => el.clientFirm.clientFirmId === rowData.clientFirmId)?.vehicles.length,
        }
    }

    const getTariffs = () => {
        let tariffs: ITariff[] = [];
        if (tariffSummaryRef.current && tariffSummaryRef.current.invoicedVehicles) {
            tariffs = [...tariffs, ...tariffSummaryRef.current!.invoicedVehicles.map(el => el.tariff)];
        }

        if (tariffSummaryRef.current && tariffSummaryRef.current.unInvoicedVehicles) {
            tariffs = [...tariffs, ...tariffSummaryRef.current!.unInvoicedVehicles.map(el => el.tariff)];
        }

        return tariffs;
    }

    const showUnifyTariffs = () => {
        if (!selectedTariffToUpdate) return;
        showDialog({
            newContent: <UnifyTariffs getVehicles={changeSourceTariffCb} targetTariff={selectedTariffToUpdate}
                                      tariffOptions={getTariffs()} onCreateCb={getSummaryData}/>,
            heading: <div> {f({id: "tariffUnification"})}</div>,
            style: {minWidth: "60vw"}

        });
    }

    const showMassEditTariff = () => {
        if (!selectedTariffToUpdate) return;
        showDialog({
            newContent: <MassEditSelectTariffWrapper onSubmitCallback={getSummaryData}
                                                     getVehicles={changeSourceTariffCb} tariffOptions={getTariffs()}
                                                     selectedTariff={selectedTariffToUpdate}/>,
            heading: <div> {f({id: "editTariff"})}</div>,
            style: {maxWidth: "85vw"}
        });
    }

    const changeSourceTariffCb = async (sourceTariff: ITariff): Promise<IVehicle[]> => {
        return getVehiclesByTariffSummary(sourceTariff);
    }

    const headerButtons: HeaderButton[] = [
        {
            icon: 'pi pi-equals',
            label: f({id: "tariffUnification"}),
            className: '',
            onClick: () => showUnifyTariffs()
        },
        {
            icon: 'pi pi-pencil',
            label: f({id: "editTariff"}),
            className: 'ml-3',
            onClick: () => showMassEditTariff()
        }
    ]

    const options = [
        {name: f({id: "monthlyTaxes"}), value: "month"},
        {name: f({id: "yearTaxes"}), value: "year"}
    ];

    return <div className={"flex w-full flex-column"}>
        <h2>{f({id: "allVehicleStatuses"})}</h2>
        <div className={"w-full flex grid"}>
            <div className="col-12 md:col-6" style={{maxHeight: "80vh"}}>
                {showPieSkeleton &&
                    <Skeleton width="40vw" height="80vh" borderRadius="80rem"></Skeleton>}

                <canvas style={{display: showPieSkeleton ? "none" : ""}} ref={allVehiclesChart}
                        id="аllVehiclesChart"></canvas>
            </div>

            <div className="col-12 md:col-6" style={{maxHeight: "80vh"}}>
                {showPieSkeleton ?
                    <Skeleton width="40vw" height="80vh" borderRadius="80rem"></Skeleton>
                    : <>
                        <SelectButton value={selectedTariffTypeChart} allowEmpty={false} optionLabel="name"
                                      onChange={(e: SelectButtonChangeEvent) => setSelectedTariffTypeChart(e.value)}
                                      options={options}/>
                        <canvas ref={tariffsChart} id="myChart"></canvas>
                    </>
                }
            </div>
        </div>

        {showTable &&
            <div className={"w-full mt-4 flex justify-content-center flex-column align-items-center"}>
                <h2 className={"w-full text-center"}>{f({id: "firmsAndVehiclesForSelectedTariff"}, {
                    invoiced: f({id: selectedType}),
                    tariff: selectedTariffToUpdate?.tarifName
                })}</h2>

                <div className={"w-full"} id={"vehiclesTable"}>
                    <ReactiveTable columnOrder={["tariffName", "tariffTypeName", "vehiclesCount"]}
                                   data={selectedTariffSummaryForMainTable}
                                   showFilters={false}
                                   showPaginator={false}
                                   selectionKey={"index"}
                                   rebuildColumns={rebuildColumns?.getTime()}
                                   showHeader={false}
                                   paginatorOptions={[100000]}
                                   expandable
                                   dtProps={{
                                       rowExpansionTemplate: mainTableRowExpansionTemplate,
                                       expandedRows: mainTableExpandedRows,
                                       onRowToggle: handleMainTableRowExpansion,
                                   }}
                    />
                </div>
            </div>

        }


    </div>

};

export default ClientFirmsSummaryDashboard;
